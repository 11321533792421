@tailwind base;
@tailwind components;
@tailwind utilities;
html, body,div #app, #root {
  height: 100vh;
  background-color:#1B1717;
  box-sizing: border-box;
  margin: 0px;
  padding:0px;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}